import React, { Fragment, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Button,
} from '@mui/material';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';

export interface IFieldMapping {
  oldField: string;
  newField: string;
}

type PropsType = {
  sourceFields: string[];
  destinationFields: string[];
  onApplyMapping: (mappings: { [key: string]: string }) => void;
};

const MappingData: React.FC<PropsType> = ({ sourceFields, destinationFields, onApplyMapping }) => {
  const [mappings, setMappings] = useState<IFieldMapping[]>([]);

  const submitMapping = () => {
    onApplyMapping(
      Object.fromEntries(mappings.map(({ oldField, newField }) => [oldField, newField])),
    );
  };

  const handleMappingChange = (sourceField: string, selectedValue: string) => {
    setMappings((prevMappings) => {
      const updatedMappings = prevMappings.filter(
        (mapping) => mapping.oldField !== sourceField,
      );

      if (selectedValue !== 'Do Not Import') {
        updatedMappings.push({
          oldField: sourceField,
          newField: selectedValue,
        });
      }

      return updatedMappings;
    });
  };
  return (
    <Container maxWidth="md">
      <Typography variant="h5" mb={3} textAlign="center">
        Map Source Fields to Destination Fields
      </Typography>
      <Box>
        <Grid container spacing={1}>
          {destinationFields.map((sourceField, index) => (
            <Fragment key={index}>
              <Grid item xs={12} sm={6} display="flex" alignItems="center">
                {sourceField}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{ minWidth: 200 }}
                >
                  <Select
                    value={
                      mappings.find((mapping) => mapping.oldField === sourceField)?.newField || 'Do Not Import'
                    }
                    onChange={(e) => handleMappingChange(sourceField, e.target.value)}
                  >
                    <MenuItem value="Do Not Import" disabled={!mappings.find((mapping) => mapping.newField)}>Do Not Import</MenuItem>
                    {sourceFields.map((destField) => (
                      <MenuItem
                        key={destField}
                        value={destField}
                        disabled={
                          Boolean(mappings.find((mapping) => mapping.newField === destField))
                        }
                      >
                        {destField}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Box>
      <Button
        variant="contained"
        endIcon={<ArrowRightIcon fill="white" />}
        sx={{ py: 1.5, borderRadius: 2, mt: 4, float: 'right' }}
        disabled={mappings.length !== destinationFields.length}
        onClick={submitMapping}
      >
        Apply Mapping
      </Button>
    </Container>
  );
};

export default MappingData;
