import { Alert } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import config from '../../config';

export const FirstTimeAlert = () => {
  const isAdmin = useAppSelector((state) => state.userSlice.user.isSuperUser);
  const showAlert = useAppSelector((state) => state.userSlice.addition.firstTimeAlert);
  if (isAdmin || !showAlert) return <div />;
  return (
    <Alert sx={{ mb: 2 }} severity="info">
      First time user? Use discount code {config.firstTimeCoupon.code} on your
      first skiptrace order to receive {config.firstTimeCoupon.discount * 100}% off!
      (up to {config.firstTimeCoupon.upto} records)
    </Alert>
  );
};
