/* eslint-disable */ import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StyledDragAndDropArea from './DragAndDropArea.styles';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ErrorMappingModal from '../ErrorMappingModal/ErrorMappingModal';
import ErrorBalanceModal from '../ErrorBalanceModal/ErrorBalanceModal';
import ErrorCountRecordsModal from '../ErrorCountRecordsModal/ErrorCountRecordsModal';
import FileUpload from '../FileUpload/FileUpload';
import {
  setLoading,
  setUploadFile,
  unsetLoading,
  unsetUploadFile,
  setDiscount,
  setFileMetadata,
} from '../../store/order/orderSlice';
import { parseFile, createCsvFile } from '../../utils/parsingFileHelper';
import { convertFileToBase64 } from '../../utils/convertFileHelper';
import { getUploadFileName } from '../../utils/getUploadFileName';
import AddSkipTraceModal from '../AddSkipTraceModal';
import MappingData from '../MappingData';
import { SkipTraceFileHeaders, SkipTraceFileTypeENUM, isValidFileHeaders } from '../../utils/skipTraceFileHeaders';
import Bill from '../Bill/Bill';
import config from '../../config';

type PropsType = {
  onClick?: () => void;
  className?: string;
  skipTraceType: string,
  isOpenSkipTraceModal?: boolean;
  setIsOpenSkipTraceModal?: (value: boolean) => void
} & React.PropsWithChildren;

interface IObject {
  [key:string]: string;
}
interface IFileInfo {
  name: string;
  size: number;
  fields: string[];
  records: number;
  data: IObject[];
}

const initialFileInfo: IFileInfo = { name:'', size: 0, records: 0, fields: [], data: [] }

const DragAndDropArea: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(
    (state) => state.orderSlice.addition.isLoading
  );
  const userInfo = useAppSelector((state) => state.userSlice.user);
  const currentDiscount = userInfo.discount ? userInfo.discount : 0;
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isOpenBillModal, setIsOpenBillModal] = useState(false);
  const [fileInfo, setFileInfo] = useState<IFileInfo>(initialFileInfo);
  const [isOpenErrorMappingModal, setIsOpenErrorMappingModal] = useState(false);
  const [isOpenErrorBalanceModal, setIsOpenErrorBalanceModal] = useState(false);
  const [isOpenErrorCountRecordsModal, setIsOpenErrorCountRecordsModal] =
    useState(false);

  const [isOpenMapping, setIsOpenMapping] = useState(false)
  const skipTraceFields = SkipTraceFileHeaders[props.skipTraceType]

  useEffect(()=> {
    dispatch(setDiscount({ discount: currentDiscount }));
  },[])

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const isActiveEvent = e.type === 'dragenter' || e.type === 'dragover';
    if (!isFileUpload) {
      if (isActiveEvent) {
        setIsDragActive(true);
      } else if (e.type === 'dragleave') {
        setIsDragActive(false);
      }
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    if (!isFileUpload) {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        if (e.dataTransfer.files[0].name.endsWith('.csv')) {
          handleUploadeFile(e.dataTransfer.files[0]);
        }
      }
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsOpenErrorCountRecordsModal(false);
    setIsOpenErrorBalanceModal(false);
    if (e.target.files && e.target.files[0]) {
      const currentFile = e.target.files[0];
      if (currentFile.name.endsWith('.csv')) {
        handleUploadeFile(currentFile)
      }
    }
  };

  const handleUploadeFile = async (file: File) => {
    try {
      dispatch(setLoading());
      const { records, fields, data } = await parseFile(file);
      if( records <= 1 ){
        toast.error("File should not be empty.")
      }
      else if(fields.length < skipTraceFields.length){ 
        setIsOpenErrorMappingModal(true);
      }
      else{
        var cost = 0;
        if (SkipTraceFileTypeENUM.BUSSINESS === props.skipTraceType) {
          cost = (records - 1) * userInfo.llcCost;
        } else if (
          currentDiscount !== 0 &&
          userInfo.code === config.firstTimeCoupon.code &&
          (records - 1) > config.firstTimeCoupon.upto
        ) {
          const discountAmount = config.firstTimeCoupon.upto * userInfo.rowCost * (currentDiscount / 100);
          cost = (records - 1) * userInfo.rowCost - discountAmount;
        }
        else {
          cost = (records - 1) * userInfo.rowCost * (1 - currentDiscount);
        }
        if (userInfo.balance < cost) {
          setIsOpenErrorBalanceModal(true);
        }
        else{
          setFileInfo({ name: file.name, size: file.size, records, fields, data });
          setIsFileUpload(true);
          if(!isValidFileHeaders(skipTraceFields, fields)) {
            setIsOpenMapping(true)
          }
          else{
            const convertedFile = await convertFileToBase64(file);
            const csvBase64 = convertedFile.split('base64,')[1];
            onUploadFile_(file.name, csvBase64, records, fields);
          }
        }
      }
    }
    catch (error){
      toast.error(error)
    } finally {
      dispatch(unsetLoading());
    }
  }

  const onUploadFile_ = async (name: string, csvBase64: string, records: number, fields: string[]) => {
    try {
    dispatch(
      setUploadFile({
        name: getUploadFileName(name),
        csvBase64,
        records,
        fields,
      })
    );
    setIsOpenErrorMappingModal(false);
    setIsOpenErrorCountRecordsModal(false);
    setIsOpenErrorBalanceModal(false);
    } catch (error) {
      toast.error(error)
    }
  }

  const onApplyMapping = async (mappings: IObject) => {
    try {
      const { name, records, data, fields } = fileInfo
      if(records > 1 ){
        if(props.skipTraceType === SkipTraceFileTypeENUM.BLACKLISTED){
          console.log(mappings, Object.values(mappings));
          dispatch(
            setFileMetadata({ blacklist_columns: Object.values(mappings) }),
          );
          const csvFile = await createCsvFile(data)
          const csvBase64 = window.btoa(csvFile)
          onUploadFile_(name, csvBase64, records, fields);
          setIsOpenMapping(false);
          return
        }
        const filter = (row: IObject) => {
          const newRow: IObject = {};
          skipTraceFields.forEach(newField => {
            if (mappings[newField]) {
              newRow[newField] = row[mappings[newField]];
            }
          });
          return newRow;
        }
        const csvFile = await createCsvFile(data, filter)
        const csvBase64 = window.btoa(csvFile)
        onUploadFile_(name, csvBase64, records, skipTraceFields);
        setIsOpenMapping(false);
      }
    } catch (error) {
      toast.error(error)
    }
  }

  const deleteFile = async () => {
    try {
      dispatch(unsetUploadFile());
      setIsFileUpload(false);
      setFileInfo(initialFileInfo);
      setIsOpenMapping(false)
    } catch (error) {
      toast.error(error);
    }
  };

  const onClickReviewOrder = () => {
    setIsOpenBillModal(true);
  };
  const handleCloseBillModal = () => {
    setIsOpenBillModal(false);
  };

  const onClickCancelBalanceModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorBalanceModal(false);
  };

  const onCurrentClickBalanceModal = () => {
    navigate('/payments/enter-amount');
  };

  const onClickCancelRecordsModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorCountRecordsModal(false);
  };

  const onClickCancelMappingModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorMappingModal(false);
  };

  const onClickCancelAddSkipTraceModal = () => {
    if(props.setIsOpenSkipTraceModal){
      props.setIsOpenSkipTraceModal(false);
    }
  };

  return (
    <StyledDragAndDropArea
      onClick={props?.onClick}
      className={props?.className ?? ''}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      isDragActive={isDragActive && !isFileUpload}
    >
      <FileUpload
        isLoading={isLoading}
        isFileUpload={isFileUpload}
        fileInfo={fileInfo}
        handleChange={handleChange}
        deleteFile={deleteFile}
      />
      {/* <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap" gap={2}>
        {isFileUpload && (<FormControlLabel
            sx={{ mx: 2 }}
            control={(<Checkbox
                checked={metadata?.email || false}
                onChange={() => setMetadata(prev => ({ ...prev, email: !prev.email }))}
                color="primary"
              />
            )}
            label={(
              <Typography variant="body2">
                Email required
              </Typography>
            )}
          />)}
      </Box> */}

      {!isOpenMapping && isFileUpload && (
        <>
        <div className="styled-drag-and-drop-area__text-area">
          <h1>Uploaded {fileInfo.name}</h1>
        </div>
        <Button endIcon={<ArrowRightIcon fill="white"/>} variant="contained" sx={{ py: 1.5, borderRadius: 2 }} onClick={onClickReviewOrder}>
          <h4>Review Order</h4>
        </Button>
        </>
      )}

      {isOpenMapping && (
        <MappingData
          sourceFields={fileInfo.fields}
          destinationFields={skipTraceFields}
          onApplyMapping={onApplyMapping}
        />
      )}

      {isOpenBillModal ? (
        <Bill
        skipTraceType={props.skipTraceType}
        open={isOpenBillModal}
        onClose={handleCloseBillModal}
        />
      ) : null}

      {isOpenErrorMappingModal ? (
        <ErrorMappingModal
          handleChange={handleChange}
          onCloseModal={onClickCancelMappingModal}
        />
      ) : null}

      {isOpenErrorBalanceModal ? (
        <ErrorBalanceModal
          onCloseModal={onClickCancelBalanceModal}
          onClick={onCurrentClickBalanceModal}
        />
      ) : null}

      {isOpenErrorCountRecordsModal ? (
        <ErrorCountRecordsModal
          handleChange={handleChange}
          onCloseModal={onClickCancelRecordsModal}
        />
      ) : null}
      {props.isOpenSkipTraceModal ? (
        <AddSkipTraceModal
          skipTraceType={props.skipTraceType}
          onUploadFile={handleUploadeFile}
          onCloseModal={onClickCancelAddSkipTraceModal}
        />
      ) : null}
    </StyledDragAndDropArea>
  );
};

export default DragAndDropArea;
