import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import BillModal from './BillModal';
import type { FieldType, UploadOrderRequestType } from '../../types/orderTypes';
import orderThunk from '../../store/order/orderThunk';
import userThunk from '../../store/user/userThunk';
import { SkipTraceFileTypeENUM } from '../../utils/skipTraceFileHeaders';
import config from '../../config';
import { setFileMetadata } from '../../store/order/orderSlice';

type PropType = {
  open: boolean;
  onClose: () => void;
  skipTraceType: string;
};

const Bill: React.FC<PropType> = (props) => {
  const userInfo = useAppSelector((state) => state.userSlice.user);
  const fileInfo = useAppSelector((state) => state.orderSlice.uploadFile);
  const isGetUploadFile = useAppSelector((state) => state.orderSlice.uploadFile.name);
  const fieldArray = useAppSelector((state) => state.orderSlice.uploadFile.fields);
  const [requiredEmail, setRequiredEmail] = useState(false);
  const [requiredBlacklist, setRequiredBlacklist] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  /* eslint-disable no-var */
  var currentPricePerRecord = userInfo.rowCost.toFixed(2);
  var currentDiscount = useAppSelector((state) => state.orderSlice.discount);
  var totalPrice = 0;
  var emailPricePerRecord = 0;
  var blacklistPricePerRecord = 0;
  // /* eslint-enable no-var */
  console.warn(currentDiscount);
  const calculateTotalCost = (
    baseCost: number, records: number, discount: number, extraCharges: number,
  ) => {
    if (discount !== 0 &&
      userInfo.code === config.firstTimeCoupon.code && records > config.firstTimeCoupon.upto) {
      const discountAmount = config.firstTimeCoupon.upto * (baseCost + extraCharges) * discount;
      return (records * (baseCost + extraCharges)) - discountAmount;
    }
    return (baseCost + extraCharges) * records * (1 - discount);
  };

  if (fileInfo.metadata?.blacklist_columns && fileInfo.metadata?.blacklist_columns.length > 0) {
    let baseCost = 0;
    if (!['bussines', 'getskooled', 'growth'].includes(userInfo.subscription?.type.toLowerCase() || '')) {
      baseCost = config.blacklistPricePerRecord;
      totalPrice = calculateTotalCost(baseCost, fileInfo.records - 1, currentDiscount, 0);
      currentPricePerRecord = baseCost.toFixed(2);
    }
    currentPricePerRecord = baseCost.toFixed(2);
  } else {
    if (!['bussines', 'getskooled'].includes(userInfo.subscription?.type.toLowerCase() || '')) {
      if (requiredEmail) { emailPricePerRecord = config.emailPricePerRecord; }
      if (requiredBlacklist) { blacklistPricePerRecord = config.blacklistPricePerRecord; }
    }
    const extraCharges = emailPricePerRecord + blacklistPricePerRecord;
    if (SkipTraceFileTypeENUM.BUSSINESS === props.skipTraceType) {
      currentDiscount = 0;
      totalPrice = (fileInfo.records - 1) * (userInfo.llcCost + extraCharges);
      currentPricePerRecord = userInfo.llcCost.toFixed(2);
    } else {
      const baseCost = userInfo.rowCost;
      totalPrice = calculateTotalCost(
        baseCost, fileInfo.records - 1, currentDiscount, extraCharges,
      );
      currentPricePerRecord = userInfo.rowCost.toFixed(2);
    }
  }
  const discount = (currentDiscount * 100).toFixed(2);

  useEffect(() => {
    if (!isGetUploadFile) {
      navigate('/import-list');
    }
  }, [isGetUploadFile, navigate]);

  useEffect(() => {
    if (SkipTraceFileTypeENUM.BLACKLISTED !== props.skipTraceType) {
      dispatch(
        setFileMetadata(
          { email: requiredEmail, blacklist: requiredBlacklist },
        ),
      );
    }
  }, [requiredEmail, requiredBlacklist, dispatch, props.skipTraceType]);

  const onClickPayBulkSkip = async () => {
    try {
      const newFieldArray = fieldArray.map((item) => {
        const newItem: FieldType = {
          oldField: item.oldField,
          newField: item.oldField,
        };
        return newItem;
      });
      const fileRequest: UploadOrderRequestType = {
        file: fileInfo.csvBase64,
        fileName: fileInfo.name,
        fields: newFieldArray,
        records: fileInfo.records - 1,
        metadata: fileInfo.metadata,
      };
      await dispatch(orderThunk.uploadFile(fileRequest)).unwrap();
      navigate('/dashboard');
      await dispatch(userThunk.loginByToken()).unwrap();
      await dispatch(userThunk.getOrderCount()).unwrap();
    } catch (error) {
      toast.error(error);
    }
  };

  const handleEmailRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setRequiredEmail(value === 'Yes');
  };

  const handleBlacklistRadioChange =
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setRequiredBlacklist(value === 'Yes');
    };

  const sectionArray = [
    {
      title: 'Skip Trace Type:',
      info: <h2>{props.skipTraceType}</h2>,
    },
    {
      title: 'Total Records:',
      info: <h2>{fileInfo.records - 1}</h2>,
    },
    {
      title: 'Discount:',
      info: <h2>{discount}%</h2>,
    },
    {
      title: 'Price/record:',
      info: <h2>${currentPricePerRecord}</h2>,
    },
    {
      title: 'Email Price/record:',
      info: <h2>${config.emailPricePerRecord}</h2>,
    },
    {
      title: 'Blacklist Price/record:',
      info: <h2>${config.blacklistPricePerRecord}</h2>,
    },
    {
      title: 'Would you like to include emails?',
      info: (
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          value={requiredEmail ? 'Yes' : 'No'}
          onChange={handleEmailRadioChange}
        >
          <FormControlLabel
            value="Yes"
            control={<Radio size="small" />}
            label="Yes"
          />
          <FormControlLabel value="No" control={<Radio size="small" />} label="No" />
        </RadioGroup>
      ),
    },
    {
      title: 'Would you like to exclude blacklisted phone numbers?',
      info: (
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          value={requiredBlacklist ? 'Yes' : 'No'}
          onChange={handleBlacklistRadioChange}
        >
          <FormControlLabel
            value="Yes"
            control={<Radio size="small" />}
            label="Yes"
          />
          <FormControlLabel value="No" control={<Radio size="small" />} label="No" />
        </RadioGroup>
      ),
    },
    {
      title: 'Total Price:',
      info: (
        <Typography color="primary" sx={{ fontWeight: 'bold', fontSize: '24px' }}>
          ${totalPrice.toFixed(2)}
        </Typography>
      ),
    },
  ];

  const blacklistSectionArray = [
    {
      title: 'Skip Trace Type:',
      info: <h2>{props.skipTraceType}</h2>,
    },
    {
      title: 'Total Records:',
      info: <h2>{fileInfo.records - 1}</h2>,
    },
    {
      title: 'Discount:',
      info: <h2>{discount}%</h2>,
    },
    {
      title: 'Price/record:',
      info: <h2>${currentPricePerRecord}</h2>,
    },
    {
      title: 'Total Price:',
      info: (
        <Typography color="primary" sx={{ fontWeight: 'bold', fontSize: '24px' }}>
          ${totalPrice.toFixed(2)}
        </Typography>
      ),
    },
  ];

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <BillModal
      {...props}
      sectionArray={
        SkipTraceFileTypeENUM.BLACKLISTED === props.skipTraceType
          ? blacklistSectionArray
          : sectionArray
      }
      isChecked={isChecked}
      handleCheckboxChange={handleCheckboxChange}
      onClickPayBulkSkip={onClickPayBulkSkip}
    />
  );
};

export default Bill;
