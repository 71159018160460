export const orderTableHead = [
  // {
  //   title: '#',
  //   name: 'userFileId',
  //   sortDirection: 'ASC',
  //   isSort: true,
  //   isFilter: false,
  // },
  {
    title: 'Files',
    name: 'outputFile',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'Order name',
    name: 'name',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'Total Records',
    name: 'totalRecords',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Successful',
    name: 'successfullRecords',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  {
    title: 'Email',
    name: 'email',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  {
    title: 'BlackList',
    name: 'blacklist',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: false,
  },
  // {
  //   title: 'Failed',
  //   name: 'failedRecords',
  //   sortDirection: 'ASC',
  //   isSort: true,
  //   isFilter: false,
  // },
  {
    title: 'Status',
    name: 'status',
    sortDirection: 'ASC',
    isSort: false,
    isFilter: true,
  },
  {
    title: 'Price',
    name: 'price',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
  // {
  //   title: 'Notes',
  //   name: 'notes',
  //   sortDirection: 'ASC',
  //   isSort: true,
  //   isFilter: false,
  // },
  {
    title: 'Date',
    name: 'createdAt',
    sortDirection: 'ASC',
    isSort: true,
    isFilter: false,
  },
];
