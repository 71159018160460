import type { AxiosResponse } from 'axios';
import type { FilterRequestType } from '../../types/filterTypes';
import type {
  ChangeCostType,
  ChangeInfoType,
  DataBulkType,
  DataBulkUserType,
  DataCardType,
  DataChangeUserType,
  DataCheckoutSessionType,
  DataCustomerType,
  DataGetPaymentsType,
  DataGetUsersType,
  DataType,
  LoginType,
  ResetPasswordFirstPartType,
  ResetPasswordSecondPartType,
  ResetPasswordThirdPartType,
  DataGetPromoterAuthType,
  DeleteSubscriptionResponseType,
} from '../../types/userTypes';

import type {
  AddCodeType,
  ChangeCodeType,
  DataBulkCodeType,
  DataGetCodesType,
} from '../../types/codeTypes';

import instance from '../index';

export enum AuthApiPathENUM {
  LOG_IN = '/api/auth/signin',
  SIGN_UP = '/api/auth/signup',
  SEND_CODE = '/api/auth/send-code',
  RESET_PASSWORD = '/api/auth/reset-password',
  CHANGE_OLD_PASSWORD = '/api/auth/edit-password',
}

export enum UserApiPathENUM {
  CHANGE_INFO = '/api/user/',
  AUTH_ME = '/api/user/',
  GET_ORDER_COUNT = '/api/user/count-pending',
  EDIT_PASSWORD = '/api/user/edit-password',
  GET_PAYMENTS = '/api/user/transaction',
  GET_PROMOTER_AUTH = '/api/user/promoter-auth',
}

export enum PaymentApiPathENUM {
  CREATE_CARD = '/api/payment/create-card',
  CREATE_SESSION = '/api/payment/create',
  CREATE_SUBSCRIPTION = '/api/payment/subscription',
  DELETE_SUBSCRIPTION= 'api/payment/subscription-delete',
  GET_CARDS = '/api/payment/methods',
  AUTO_PAY = '/api/payment/auto-pay',
  SET_DEFAULT_PM = '/api/payment/set-default-pm',
  GET_CUSTOMER = '/api/payment/customer',
  DELETE_CARD = '/api/payment/card',
}

export enum AdminApiPathENUM {
  GET_ORDER_COUNT = '/api/admin/count-pending',
  GET_USER_COUNT = '/api/admin/count-users',
  GET_USERS = '/api/admin/',
  CHANGE_COST = '/api/admin/',
  EXPORT_USERS = 'api/admin/export-users',
}

export enum CodeApiPathENUM {
  GET_CODES = '/api/admin/codes',
  GET_CODE_COUNT = '/api/admin/count-codes',
  DELETE_CODE = '/api/admin/delete-code',
  ADD_CODE = '/api/admin/add-code',
}

export const paymentApi = {
  checkoutSession: async (amount: number): Promise<AxiosResponse<DataCheckoutSessionType>> => {
    return instance.post(PaymentApiPathENUM.CREATE_SESSION, { amount });
  },

  checkoutSubscription: async (
    id: string,
  ): Promise<AxiosResponse<DataCheckoutSessionType>> => {
    return instance.post(PaymentApiPathENUM.CREATE_SUBSCRIPTION, { id });
  },

  deleteSubscription: async ():
  Promise<AxiosResponse<DeleteSubscriptionResponseType>> => {
    return instance.post(PaymentApiPathENUM.DELETE_SUBSCRIPTION);
  },

  createCard: async (): Promise<AxiosResponse<DataCheckoutSessionType>> => {
    return instance.get(PaymentApiPathENUM.CREATE_CARD);
  },

  getCards: async (): Promise<AxiosResponse<DataCardType>> => {
    return instance.get(PaymentApiPathENUM.GET_CARDS);
  },

  deleteCard: async (pm: string): Promise<AxiosResponse<DataCardType>> => {
    return instance.delete(PaymentApiPathENUM.DELETE_CARD, { params: { pm } });
  },

  getCustomer: async (): Promise<AxiosResponse<DataCustomerType>> => {
    return instance.get(PaymentApiPathENUM.GET_CUSTOMER);
  },

  autoPay: async (value: {
    amount: number;
    id: string;
  }): Promise<AxiosResponse<DataCheckoutSessionType>> => {
    const { amount, id } = value;
    return instance.post(PaymentApiPathENUM.AUTO_PAY, { amount, id });
  },

  setDefaultPM: async (payMethod: string): Promise<AxiosResponse<DataCustomerType>> => {
    return instance.post(PaymentApiPathENUM.SET_DEFAULT_PM, { payMethod });
  },
};

export const authAPI = {
  login: async (info: LoginType): Promise<AxiosResponse<DataType>> => {
    return instance.post(AuthApiPathENUM.LOG_IN, info);
  },

  signUp: async (info: LoginType): Promise<AxiosResponse<DataType>> => {
    return instance.post(AuthApiPathENUM.SIGN_UP, info);
  },

  sendCode: async (info: ResetPasswordFirstPartType): Promise<AxiosResponse<string>> => {
    return instance.get(AuthApiPathENUM.SEND_CODE, { params: { ...info } });
  },

  resetPassword: async (info: ResetPasswordSecondPartType): Promise<AxiosResponse<string>> => {
    return instance.post(AuthApiPathENUM.RESET_PASSWORD, info);
  },

  changeOldPassword: async (info: ResetPasswordThirdPartType): Promise<AxiosResponse<string>> => {
    return instance.post(AuthApiPathENUM.CHANGE_OLD_PASSWORD, info);
  },
};

export const userAPI = {
  loginByToken: async (): Promise<AxiosResponse<DataType>> => {
    return instance.get(UserApiPathENUM.AUTH_ME);
  },

  getOrderCount: async (): Promise<AxiosResponse<DataBulkType>> => {
    return instance.get(UserApiPathENUM.GET_ORDER_COUNT);
  },

  changeInfoUser: async (info: ChangeInfoType): Promise<AxiosResponse<DataChangeUserType>> => {
    return instance.post(UserApiPathENUM.CHANGE_INFO, info);
  },

  editPassword: async (info: ResetPasswordThirdPartType): Promise<AxiosResponse<string>> => {
    return instance.post(UserApiPathENUM.EDIT_PASSWORD, info);
  },

  getPayments: async (filter: FilterRequestType): Promise<AxiosResponse<DataGetPaymentsType>> => {
    return instance.get(UserApiPathENUM.GET_PAYMENTS, { params: { ...filter } });
  },

  getPromoterAuthToken: async (): Promise<AxiosResponse<DataGetPromoterAuthType>> => {
    return instance.get(UserApiPathENUM.GET_PROMOTER_AUTH);
  },
};

export const adminAPI = {
  getOrderCount: async (): Promise<AxiosResponse<DataBulkType>> => {
    return instance.get(AdminApiPathENUM.GET_ORDER_COUNT);
  },

  getUserCount: async (): Promise<AxiosResponse<DataBulkUserType>> => {
    return instance.get(AdminApiPathENUM.GET_USER_COUNT);
  },

  getUsers: async (filter: FilterRequestType): Promise<AxiosResponse<DataGetUsersType>> => {
    return instance.get(AdminApiPathENUM.GET_USERS, { params: { ...filter } });
  },

  changeCost: async (info: ChangeCostType): Promise<AxiosResponse<string>> => {
    return instance.post(AdminApiPathENUM.CHANGE_COST, info);
  },

  exportUsers: async (): Promise<AxiosResponse<string>> => {
    return instance.get(AdminApiPathENUM.EXPORT_USERS);
  },
};

export const codeAPI = {
  getCodeCount: async (): Promise<AxiosResponse<DataBulkCodeType>> => {
    return instance.get(CodeApiPathENUM.GET_CODE_COUNT);
  },

  deleteCode: async (info: ChangeCodeType): Promise<AxiosResponse<string>> => {
    const couponId = info.couponId;
    return instance.delete(CodeApiPathENUM.DELETE_CODE, { params: { couponId } });
  },

  addCode: async (info: AddCodeType): Promise<AxiosResponse<string>> => {
    return instance.post(CodeApiPathENUM.ADD_CODE, info);
  },

  getCodes: async (filter: FilterRequestType): Promise<AxiosResponse<DataGetCodesType>> => {
    return instance.get(CodeApiPathENUM.GET_CODES, { params: { ...filter } });
  },
};
