import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import StyledDashBoardMainContent from './DashBoardMainContent.styles';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import SearchInput from '../../../../components/SearchInput';
import type { FilterType } from '../../../../types/filterTypes';
import useDebounce from '../../../../utils/useDebounce';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useLocationName } from '../../../../utils/checkLocationHelper';
import AddCodeModal from '../../../../components/AddCodeModal/AddCodeModal';
import userThunk from '../../../../store/user/userThunk';
import type { AddCodeType } from '../../../../types/codeTypes';
import { ReactComponent as Calendar } from '../../../../assets/icons/calendar.svg';
import DropDownList from '../DropDownList';
import { formattingDate } from '../../../../utils/formattingDateHelper';
import { adminAPI } from '../../../../api/services/userApi';

type PropsType = {
  filter: FilterType;
  setFilter: (title: string, value: string | Date | null) => void;
};

const DashBoardMainContent: React.FC<PropsType> = (props) => {
  const [isOpenAddCodeModal, setIsOpenAddCodeModal] = useState(false);
  const dispatch = useAppDispatch();
  const wrapperRef = useRef(null);
  const [isOpenDropDownList, setIsOpenDropDownList] = useState(false);
  const filter = useAppSelector((state) => state.userSlice.filter);
  const userProfile = useAppSelector((store) => store.userSlice.user);
  const [search, setSearch] = useState(props.filter.findString);
  const currentSearch = useDebounce(search);
  const isAdminUser = useAppSelector((store) => store.userSlice.user.isSuperUser);
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const locationName = useLocationName();
  const isCodePage = locationName === 'codes';
  const isUserPage = locationName === 'users';

  useEffect(() => {
    props.setFilter('findString', currentSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch]);

  const onOpen = () => {
    setIsOpenDropDownList(true);
  };

  const onClose = () => {
    setIsOpenDropDownList(false);
  };

  const onOpenAddCodeModal = () => {
    setIsOpenAddCodeModal(true);
  };

  const onAddCode = async (code_: string, one_claim_per_user_:boolean,
    discount_: number, expires_at_:string) => {
    try {
      const code: AddCodeType = {
        code: code_,
        one_claim_per_user: one_claim_per_user_,
        discount: discount_,
        expires_at: expires_at_,
      };
      await dispatch(userThunk.addCode(code)).unwrap();
      await dispatch(userThunk.getAdminCodes(filter)).unwrap();
      await dispatch(userThunk.getAdminCodeCount()).unwrap();
    } catch (error) {
      toast.error(error);
    } finally {
      onClickCancelAddCodeModal();
    }
  };

  const onExportUsers = async () => {
    try {
      const res = await adminAPI.exportUsers();
      if (res.status === StatusCodes.OK) {
        const blob = new Blob([res.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'users.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const onClickCancelAddCodeModal = () => {
    setIsOpenAddCodeModal(false);
  };

  return (
    <StyledDashBoardMainContent>
      <div className="dashboard-container__search-area">
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            flexDirection: { xs: 'column', lg: 'row' },
            width: '100%',
          }}
        >
          <Box sx={{ width: { xs: '100%', lg: '50%' } }}>
            <SearchInput onChange={handleChangeSearch} value={search} nameInput="search" placeHolder="Search" />
          </Box>
          {!userProfile.isSuperUser ? (
            <Box sx={{ width: { xs: '100%', lg: '50%', xl: '35%' } }}>
              <div ref={wrapperRef}>
                <div className="date-picker-area text-4" onClick={onOpen}>
                  <div className="date-picker">
                    {!props.filter.startDate
                      ? (
                        <div className="default-text">Start date</div>
                      ) : (
                        <div>{formattingDate(props.filter?.startDate)}</div>
                      )
                    }
                    <Calendar />
                  </div>
                  <div className="default-text">to</div>
                  <div className="date-picker">
                    {!props.filter.endDate
                      ? (
                        <div className="default-text">End date</div>
                      ) : (
                        <div>{formattingDate(props.filter?.endDate)}</div>
                      )}
                    <Calendar />
                  </div>
                </div>

                {
                  isOpenDropDownList ? (
                    <div className="date-area">
                      <DropDownList
                        filter={props.filter}
                        setFilter={props.setFilter}
                        onCloseDropDownList={onClose}
                      />
                    </div>
                  ) : null
                }
              </div>
            </Box>
          ) : null}
          {isCodePage && isAdminUser ? (
            <Box sx={{ width: { xs: '100%', lg: '50%' }, textAlign: 'end' }}>
              <Button
                onClick={() => onOpenAddCodeModal()}
                endIcon={<PlusIcon fill="white" />}
                sx={{
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: 'capatilize',
                }}
                variant="contained"
                >
                  Add Discount Code
              </Button>
            </Box>
          ) : null}
          {isUserPage && isAdminUser ? (
            <Box sx={{ width: { xs: '100%', lg: '50%' }, textAlign: 'end' }}>
              <Button
                onClick={onExportUsers}
                // endIcon={<PlusIcon fill="white" />}
                sx={{
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: 'capatilize',
                }}
                variant="contained"
                >
                  Export Users
              </Button>
            </Box>
          ) : null}
        </Box>
      </div>
      {isAdminUser ? (
        <div>
          <h3>{locationName}</h3>
        </div>
      ) : null}
      {
        isOpenAddCodeModal ? (
          <div>
            <AddCodeModal
              onClick={onAddCode}
              onCloseModal={onClickCancelAddCodeModal}
            />
          </div>
        ) : null
      }
      <Outlet />
    </StyledDashBoardMainContent>
  );
};

export default DashBoardMainContent;
