import { Fragment, useState } from 'react';
import { Box, Card, Button, Grid, useTheme, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DragAndDropArea from '../../components/DragAndDropArea/DragAndDropArea';
import PageTitle from '../../components/PageTitle';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { SkipTraceFileTypeENUM, SkipTraceFileHeaders } from '../../utils/skipTraceFileHeaders';
import { FirstTimeAlert } from '../../components/Alerts';

const ImportListPage = () => {
  const [isOpenAddSkipTraceModal, setIsOpenAddSkipTraceModal] = useState(false);
  const [skipTraceType, setSkipTraceType] = useState<string | null>(null);
  const theme = useTheme();

  const onOpenAddSkipTraceModal = () => {
    setIsOpenAddSkipTraceModal(true);
  };

  return (
    <Box
      sx={{
        px: '30px',
        py: '25px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box>
        <FirstTimeAlert />
        {skipTraceType ? (
          <PageTitle
            title="Skip Trace"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Button
              endIcon={<PlusIcon fill="white" />}
              sx={{
                px: 4,
                py: 1.5,
                borderRadius: 2,
                textTransform: 'capitalize',
              }}
              variant="contained"
              onClick={() => onOpenAddSkipTraceModal()}
            >
              Add Single Trace
            </Button>
          </PageTitle>
        ) : <PageTitle title="Skip Trace" /> }
      </Box>
      <Card sx={{ p: { xs: 2, sm: 5 }, borderRadius: 5 }}>
        {skipTraceType ? (
          <>
            <Box display={{ xs: 'block', sm: 'flex' }} alignItems="center" justifyContent="space-between" gap={1}>
              <Button
                startIcon={<ArrowLeftIcon fill={theme.palette.primary.main} />}
                sx={{
                  px: 2,
                  borderRadius: 2,
                  textTransform: 'capitalize',
                }}
                onClick={() => setSkipTraceType(null)}
              >
                Back
              </Button>
              <Typography>
                {skipTraceType}
              </Typography>
            </Box>
            <DragAndDropArea
              skipTraceType={skipTraceType}
              isOpenSkipTraceModal={isOpenAddSkipTraceModal}
              setIsOpenSkipTraceModal={setIsOpenAddSkipTraceModal}
            />
          </>
        ) : (
          <Grid container spacing={3} py={5}>
            {Object.values(SkipTraceFileTypeENUM).map((fileType, index) => (
              <Fragment key={index}>
                <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography component="h2" fontWeight={fileType === SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS ? 700 : 0}>
                    {fileType}
                    {
                      fileType === SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS && (
                        <Typography ml={0.5} display="inline-block">(Default Skip-Trace)</Typography>
                      )
                    }
                  </Typography>
                  <Tooltip
                    title={`Required Headers -> ${SkipTraceFileHeaders[fileType].join(', ')}`}
                  >
                    <InfoOutlinedIcon fontSize="small" sx={{ '&:hover': { color: 'primary.main' } }} />
                  </Tooltip>
                </Box>
                </Grid>
                <Grid item xs={12} sm={6} textAlign="center">
                <Button
                  endIcon={<ArrowRightIcon fill="white" />}
                  sx={{
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  onClick={() => setSkipTraceType(fileType)}
                >
                  Upload File
                </Button>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        )}
      </Card>
    </Box>
  );
};

export default ImportListPage;
