import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import GhostButton from '../GhostButton/GhostButton';
import StyledTableBodyRow from './TableBodyRow.styles';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import type { OrderType } from '../../types/orderTypes';
import { checkIsSuccessfulErrorMessage } from '../../utils/errorCheckHelper';
import { orderAPI } from '../../api/services/orderApi';
import { formattingDate } from '../../utils/formattingDateHelper';

type PropsType = {
  item: OrderType;
  className?: string;
};

const TableBodyRow: React.FC<PropsType> = (props) => {
  const { item, className = '' } = props;
  const isSuccessStatus = checkIsSuccessfulErrorMessage(item.status);
  const currentStatusClassName = (status: string) => `${status.split(' ').join('-').toLowerCase()}-status`;

  const correctDisplayInfo = useCallback((info: number) => {
    return isSuccessStatus ? info : '-';
  }, [isSuccessStatus]);

  async function onClickLink(fileName: string, e: React.MouseEvent<HTMLDivElement>): Promise<void> {
    e.preventDefault();
    try {
      if (fileName) {
        const res = await orderAPI.downloadOutputFile(fileName);
        if (res.status === StatusCodes.OK) {
          const link = document.createElement('a');
          link.href = res.data.data.file;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        toast.error('Skip Ready file is not available yet.');
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const orderValueArray = useMemo(() => {
    return [
      // { content: item.userFileId },

      {
        content: (
          <GhostButton
            disabled={!isSuccessStatus}
          >
            <div
              onClick={(e) => onClickLink(item?.nameFileAdmin || '', e)}
            >
              <DownloadIcon />
            </div>
          </GhostButton>
        ),
      },

      {
        content: item.name,
        type: 'p',
      },

      {
        content: item.totalRecords,
      },

      {
        content: correctDisplayInfo(item.successfullRecords),
      },

      { content: item.metadata.email ? 'Yes' : 'No' },

      { content: item.metadata.blacklist ? 'Yes' : 'No' },

      // {
      //   content: correctDisplayInfo(item.failedRecords),
      // },

      {
        content: item.status,
        type: 'div',
        className: clsx(['order-status', currentStatusClassName(item.status)]),
      },

      { content: `$${item.price.toFixed(2)}` },

      // { content: item.notes || '-' },

      { content: formattingDate(item.createdAt) },
    ];
  }, [correctDisplayInfo,
    isSuccessStatus,
    item.createdAt,
    // item.failedRecords,
    item.name,
    item?.nameFileAdmin,
    // item.notes,
    item.price,
    item.status,
    item.successfullRecords,
    item.totalRecords,
    // item.userFileId,
    item.metadata,
  ]);

  return (
    <StyledTableBodyRow>
      {orderValueArray.map((item, index) => {
        if (item.type === 'p') {
          return (
            <td key={index}>
              <p className="styled-table-row__text">{item.content}</p>
            </td>
          );
        }
        if (item.type === 'div') {
          return (
            <td key={index}>
              <div className={clsx(item.className)}>{item.content}</div>
            </td>
          );
        }
        return (
          <td key={index}>
            <div>
              {item.content}
            </div>
          </td>
        );
      })}
    </StyledTableBodyRow>
  );
};

export default TableBodyRow;
