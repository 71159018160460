import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FieldType, Metadata, OrderSliceType } from '../../types/orderTypes';
import thunk from './orderThunk';
import { datesSelect } from './datesSelect';
import { orderStatusFilter } from './orderStatusFilter';
import type { FilterType } from '../../types/filterTypes';

const initialState: OrderSliceType = {
  orderStatusFilter,
  datesSelect,
  orders: [],

  options: {
    page: 1,
    perPage: 15,
    totalPages: 0,
    totalRecords: 0,
  },

  filter: {
    findString: '',
    startDate: null,
    endDate: null,
    page: '1',
    sortField: '',
    sortDirect: '',
    status: '',
    perPage: '15',
  },

  addition: {
    errorMessage: '',
    isLoading: false,
  },

  uploadFile: {
    csvBase64: '',
    name: '',
    fields: [],
    records: 0,
    metadata: {},
  },
  discount: 0,
};

export const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    changeFilter: (
      state,
      action: PayloadAction<{ title: string; value: FilterType[keyof FilterType] }>,
    ) => {
      if (!action) {
        return;
      }
      if (action.payload.title in state.filter) {
        (
          state.filter[action.payload.title as keyof FilterType] as FilterType[keyof FilterType]
        ) = action.payload.value;
      }
    },
    setCurrentField: (state, action: PayloadAction<{ oldField: string; newField: string }>) => {
      if (!action) {
        return;
      }
      const index = state.uploadFile.fields.findIndex((item) => {
        return item.oldField === action.payload.oldField;
      });
      if (index !== -1) {
        state.uploadFile.fields[index].newField = action.payload.newField;
      }
    },

    setDiscount: (state, action: PayloadAction<{
        discount: number;
       }>) => {
      if (!action) {
        return;
      }
      state.discount = action.payload.discount;
    },

    setUploadFile: (
      state, action: PayloadAction<{
        name: string;
        csvBase64: string;
        fields: Array<string>;
        records: number;
        metadata?: Metadata;
      }>,
    ) => {
      if (!action) {
        return;
      }

      const newArrayFields = action.payload.fields.map((item) => {
        const newItem: FieldType = {
          oldField: item,
          newField: '',
        };
        return newItem;
      });
      state.uploadFile.name = action.payload.name;
      state.uploadFile.csvBase64 = action.payload.csvBase64;
      state.uploadFile.records = action.payload.records;
      state.uploadFile.fields = newArrayFields;
    },

    setFileMetadata: (
      state, action: PayloadAction<Metadata>,
    ) => {
      if (!action) {
        return;
      }
      state.uploadFile.metadata = {
        ...state.uploadFile.metadata, ...action.payload,
      };
    },

    unsetUploadFile: (state) => {
      state.uploadFile = initialState.uploadFile;
    },

    setLoading: (state) => {
      state.addition.isLoading = true;
    },
    unsetLoading: (state) => {
      state.addition.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunk.getOrders.fulfilled, (state, { payload }) => {
      if (!payload?.files || !payload?.options) {
        return;
      }
      state.orders = payload.files;
      state.options = payload.options;
    });

    builder.addCase(thunk.getAdminOrders.fulfilled, (state, { payload }) => {
      if (!payload?.files || !payload?.options) {
        return;
      }
      state.orders = payload.files;
      state.options = payload.options;
    });
  },
});

export const {
  changeFilter,
  setLoading,
  unsetLoading,
  setCurrentField,
  setUploadFile,
  unsetUploadFile,
  setDiscount,
  setFileMetadata,
} = orderSlice.actions;
export default orderSlice.reducer;
