export enum SkipTraceFileTypeENUM {
  OWNER_MAILING_ADDRESS = 'Owner Name / Mailing Address Skip Trace',
  BUSSINESS = 'Business Skip Trace',
  PROPERTY_ADDRESS = 'Property Address Skip Trace',
  EMAIL = 'Email Skip Trace',
  PHONE = 'Phone Skip Trace',
  BLACKLISTED = 'BlackList',
}

interface ISkipTraceFileHeaders {
  [key:string] : string[];
}

export const SkipTraceFileHeaders: ISkipTraceFileHeaders = {
  [SkipTraceFileTypeENUM.OWNER_MAILING_ADDRESS]: [
    'FirstName',
    'LastName',
    'RecipientAddress',
    'RecipientCity',
    'RecipientState',
    'RecipientPostalCode',
    'PropertyAddress',
    'PropertyCity',
    'PropertyState',
    'PropertyPostalCode',
  ],
  [SkipTraceFileTypeENUM.BUSSINESS]: [
    'Owner',
    'FN',
    'LN',
    'OA',
    'OC',
    'OS',
    'OZ',
    'PID',
    'SA',
    'SC',
    'SS',
    'SZ',
    'LSF',
    'BSF',
    'LU',
    'ALV',
    'AIV',
    'AV',
    'AY',
    'MI',
    'ZD',
  ],
  [SkipTraceFileTypeENUM.PROPERTY_ADDRESS]: ['ID', 'Address', 'City', 'State', 'Zip'],
  [SkipTraceFileTypeENUM.EMAIL]: ['Email'],
  [SkipTraceFileTypeENUM.PHONE]: ['Id', 'Phone Number'],
  [SkipTraceFileTypeENUM.BLACKLISTED]: [
    'phone_1_number',
    'phone_2_number',
    'phone_3_number',
    'phone_4_number',
    'phone_5_number',
  ],
};

export const isValidFileHeaders = (skipTraceFields: string[], fileFields: string[]) => {
  return fileFields.every(
    (field, index) => skipTraceFields[index] &&
    skipTraceFields[index].toLowerCase().trim() === field.toLowerCase().trim(),
  );
};
