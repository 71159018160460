/* eslint-disable */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as Frame } from '../../assets/icons/Frame 19.svg';
import { ReactComponent as SubscriptionIcon } from '../../assets/icons/subscription-icon.svg';
import StyledMenu from './Menu.styles';
import { ReactComponent as GridIcon } from '../../assets/icons/grid.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg';
import { ReactComponent as HeartIcon } from '../../assets/icons/heart.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { ReactComponent as BiSolidCouponIcon } from '../../assets/icons/coupon.svg';
import { ReactComponent as HowToUseSiteIcon } from '../../assets/icons/video-recording.svg';
import { ReactComponent as PackageIcon } from '../../assets/icons/package.svg';
import { ReactComponent as EditAccountIcon } from '../../assets/icons/edit-account.svg';
import { useAppSelector } from '../../store/hooks';
import { useLocationName } from '../../utils/checkLocationHelper';
import MobileMenu from '../MobileMenu/MobileMenu';
import LogoutButton from '../LogoutButton';

const menuArray = [
  {
    icon: <GridIcon />,
    title: 'Dashboard',
    url: '/dashboard',
  },
  {
    icon: <PackageIcon />,
    title: 'Skip Trace',
    url: '/skip-trace',
  },
  {
    icon: <CreditCardIcon />,
    title: 'Payments',
    url: '/payments',
  },
  {
    icon: <HeartIcon />,
    title: 'Affiliate',
    url: '/affiliate',
  },
  {
    icon: <HowToUseSiteIcon />,
    title: 'How to Use site',
    url: '/how-to-use-site',
  },
  {
    icon: <SubscriptionIcon />,
    title: 'Plans',
    url: '/subscription',
  },
];

const menuAdminArray = [
  {
    icon: <GridIcon />,
    title: 'Orders',
    url: '/orders',
  },
  {
    icon: <UsersIcon />,
    title: 'Users',
    url: '/users',
  },
  {
    icon: <BiSolidCouponIcon fill="rgb(113, 21, 23)" />,
    title: 'Discount codes',
    url: '/codes',
  },
];

const Menu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const locationName = useLocationName();
  const isCurrentTab = (item: {
    icon: React.ReactNode;
    title: string;
    url: string;
  }) => item.url === `/${locationName}`;
  const isAdminUser = useAppSelector(
    (state) => state.userSlice.user.isSuperUser
  );

  const handleClick = (url: string) => {
    navigate(url);
  };

  return (
    <>
      {isMobile ? (
        <MobileMenu
          onClick={handleClick}
          menuArray={isAdminUser ? menuAdminArray : menuArray}
          isCurrentTab={isCurrentTab}
        />
      ) : (
        <StyledMenu>
          <div className="styled-menu__main-content">
            <div className="styled-menu__main-content__touched-area">
              {isAdminUser
                ? menuAdminArray.map((item, index) => (
                    <div
                      key={index}
                      className={clsx([
                        'styled-menu__main-content__item',
                        { selected: isCurrentTab(item) },
                      ])}
                      onClick={() => handleClick(item.url)}
                    >
                      {item.icon}
                      <p className="menu-item-title">{item.title}</p>
                    </div>
                  ))
                : menuArray.map((item, index) => (
                    <div
                      key={index}
                      className={clsx([
                        'styled-menu__main-content__item',
                        { selected: isCurrentTab(item) },
                      ])}
                      onClick={() => handleClick(item.url)}
                    >
                      {item.icon}
                      <p className="menu-item-title">{item.title}</p>
                    </div>
                  ))}
            </div>
          </div>
          <Box display="flex" justifyContent="flex-end" width="100%" mr={3}>
            <LogoutButton color="white" />
          </Box>
          <div className="menu-img">
            <Frame />
          </div>
        </StyledMenu>
      )}
    </>
  );
};

export default Menu;
